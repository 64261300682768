define("labs-zap-search/templates/subscribed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "sXQoW6Xv",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"cell\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"grid-container\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"grid-x grid-padding-x grid-padding-y align-middle align-center subscribed\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"cell large-8 padding-top-4\"],[8],[0,\"\\n          \"],[7,\"h1\",true],[10,\"class\",\"header-xxl dcp-orange\"],[8],[0,\"Confirm Your ZAP Updates\"],[9],[0,\"\\n          \"],[7,\"p\",true],[8],[0,\" \"],[9],[0,\"\\n          \"],[7,\"span\",true],[8],[1,[28,\"fa-icon\",null,[[\"icon\",\"prefix\",\"size\"],[\"check-circle\",\"far\",\"2x\"]]],false],[9],[0,\"\\n          \"],[7,\"p\",true],[10,\"class\",\"lg\"],[8],[7,\"span\",true],[8],[0,\"Check email\"],[9],[0,\" to confirm your ZAP subscription.\"],[9],[0,\"\\n          \"],[7,\"p\",true],[8],[0,\"You will receive an email to confirm your Community Districts subscriptions. Additionally, the email contains details on how to modify your updates.\"],[9],[0,\"\\n          \"],[7,\"p\",true],[8],[0,\" \"],[9],[0,\"\\n          \"],[5,\"link-to\",[[12,\"class\",\"button\"]],[[\"@route\"],[\"show-geography\"]],{\"statements\":[[0,\"Go to Home\"]],\"parameters\":[]}],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[1,[22,\"outlet\"],false],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "labs-zap-search/templates/subscribed.hbs"
    }
  });

  _exports.default = _default;
});